// fancyBox
$('[data-fancybox]').fancybox({
    // Options will go here
    buttons : [
        'slideShow',
        'fullScreen',
        'thumbs',
        'close'
    ]
});

// fancyBox - modular boxes
$('[data-fancybox-content]').fancybox({
	// Options will go here
	hideScrollbar: false,
	buttons : [
		'slideShow',
		'fullScreen',
		'thumbs',
		'close'
	],
	btnTpl: {
		smallBtn:
			'<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small" title="{{CLOSE}}">' +
			'<img src="Public/Img/Icons/close-red.svg" alt="Menü schließen">' +
			"</button>"
	},
});

// video functions
if ($('.introductoryBack.video').length > 0) {
	var $backButton = $('.introductoryBack.video');

	var hideBackButton = function(delay) {
		$backButton.addClass("shortact");
		$backButton.delay(delay).fadeOut(300, function() {
			$backButton.removeClass("shortact");
		});
	};

	hideBackButton(3500);

	var showBackButton = function() {
		$('.introductoryBack.video').fadeIn(500);
	};


	// show back button if video has ended
	$('video.videoElement').bind('ended', function(){
		showBackButton();
	});

	// show back button if video has ended
	$('video.videoElement').bind('pause', function(){
		if(!$backButton.hasClass("act")) {
			$backButton.addClass("act");
			showBackButton();
		}
	});

	// hide back button if video is playing again
	$('video.videoElement').bind('play', function(){
		$backButton.removeClass("act");
		hideBackButton(1000);
	});

	// show Back Button on Touch event for a short moment
	document.addEventListener('touchstart', function(e) {
		$backButton.addClass("touched");
		if(!$backButton.hasClass("shortact")) {
			showBackButton();
		}
	}, false);

	document.addEventListener('touchend', function(e) {
		$backButton.removeClass("touched");
		if(!$backButton.hasClass("shortact")) {
			hideBackButton(2400);
		}
	}, false);

}

